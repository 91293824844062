import { ApiCallConfig, apiCall } from '../../../utils/apiCall';
import { Category } from './categories.types';

export const getCategories = async (params: ApiCallConfig = {}) =>
  apiCall({
    ...params,
    endpoint: `categories`,
    method: 'GET',
    reshaper: (data: Category[]) => data,
  });
