import { useRouter } from 'next/router';

import { useTranslation } from 'i18n/hooks';

import { buildItemsListingRoute } from 'modules/catalog/utils';
import { useQuery } from '@tanstack/react-query';
import { useToast } from 'modules/common/hooks';
import { MarketplaceAPI } from 'revibe-api';

export const useCategories = () => {
  const { errorToast } = useToast();
  const { data: categories } = useQuery(
    ['categories'],
    async () => {
      const { data, error } = await MarketplaceAPI.getCategories();
      if (error) {
        throw new Error(error);
      }
      if (!data) {
        return [];
      }
      return data;
    },
    {
      initialData: [],
      onError: (err: string) => {
        errorToast(err);
      },
    }
  );

  return {
    categories: categories.sort((a, b) => a.name.localeCompare(b.name)) || [],
    isLoading: false,
  };
};

export const useItemCategoriesByGroup = (
  sex?: string,
  exclude: number[] = []
) => {
  const router = useRouter();
  const { tu } = useTranslation('shop.categories');
  const { categories } = useCategories();

  return [
    {
      id: '1',
      catID: 'clothing',
      label: tu('clothing'),
      to: buildItemsListingRoute(sex, 'clothing'),
      sections:
        categories
          .filter(
            (categoryGroup) =>
              categoryGroup.group === 'clothing' &&
              exclude.indexOf(Number(categoryGroup.id)) === -1
          )
          .map((childCategory) => ({
            id: String(childCategory.id),
            label: childCategory.name,
            action: () =>
              router.push(buildItemsListingRoute(sex, childCategory.slug)),
            to: buildItemsListingRoute(sex, childCategory.slug),
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) || [],
    },
    {
      id: '2',
      catID: 'accessories-more',
      label: tu('accessories-more'),
      to: buildItemsListingRoute(sex, 'accessories-more'),
      sections:
        categories
          .filter(
            (categoryGroup) =>
              categoryGroup.group === 'accessories-more' &&
              exclude.indexOf(Number(categoryGroup.id)) === -1
          )
          .map((childCategory) => ({
            id: String(childCategory.id),
            label: childCategory.name,
            action: () =>
              router.push(buildItemsListingRoute(sex, childCategory.slug)),
            to: buildItemsListingRoute(sex, childCategory.slug),
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) || [],
    },
  ];
};
