import React from 'react';

import { useTranslation } from 'i18n/hooks';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'revibe-ui';

import { useItemCategoriesByGroup } from 'modules/item/hooks';

import { Link } from 'ui/components/Link';

export const CategoriesTreeView = () => {
  const { tu } = useTranslation();
  const femaleCategories = useItemCategoriesByGroup('female');
  const maleCategories = useItemCategoriesByGroup('male', [28, 27]);

  const data = [
    {
      label: tu('female', 'common'),
      categories: femaleCategories,
    },
    {
      label: tu('male', 'common'),
      categories: maleCategories,
    },
  ];

  return (
    <div className="bg-black">
      <Accordion type="single" collapsible>
        {data.map(({ label, categories }) => (
          <AccordionItem value={label} key={label} className="border-b-0 px-0">
            <AccordionTrigger className="py-2 text-left uppercase lg:text-lg">
              {label}
            </AccordionTrigger>
            <AccordionContent>
              <div className="mb-4">
                {categories.map((group) => (
                  <ul
                    className="space-y-1 border-l border-gray-500 pt-4 pl-4 text-white"
                    key={Number(group.id) * 200}
                  >
                    <li className="font-semibold uppercase">
                      <Link hasDecoration={false} to={group.to}>
                        {group.label}
                      </Link>
                    </li>
                    {group.sections.map((section) => (
                      <li
                        className="text-gray-500"
                        key={Number(section.id) * 2000}
                      >
                        <Link hasDecoration={false} to={section.to}>
                          {section.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};
