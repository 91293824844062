import React, { useState } from 'react';

import { useRouter } from 'next/router';

import { cn, Drawer } from 'revibe-ui';
import { useTranslation } from 'i18n/hooks';
import { Button } from 'revibe-ui';
import { AUTH_ROUTES, BLOG_ROUTES, SHOP_ROUTES, USER_ROUTES } from 'routes';

import { useAuth } from 'modules/auth/hooks/useAuth';
import { LanguageSelector } from 'modules/common/components/LanguageSelector';
import { useCategories } from 'modules/item/hooks/useCategories';
import { ITEM_FILTERS, ITEM_ORDER } from 'modules/item/utils/itemFilter';
import { CategoriesTreeView } from 'modules/catalog/components/CategoriesTreeView';
import { buildItemsListingRoute } from 'modules/catalog/utils';

import { Input } from 'ui/components/Input';
import { LogoIcon } from 'ui/icons/LogoIcon';
import { useDataLayer } from 'modules/tracking/hooks/useDataLayer';
import { fontVariables } from 'ui/fonts/fontVariables';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CategoriesPanel = ({ isOpen, onClose }: Props) => {
  const router = useRouter();
  const { tu } = useTranslation();
  const [search, setSearch] = useState('');
  const { logout, isLoggedIn } = useAuth();
  const handleLogout = async () => {
    await logout();
    onClose();
  };
  const dataLayer = useDataLayer();

  useCategories();
  const handleUserSearch = () => {
    dataLayer.trackSearch(search);
    const url = buildItemsListingRoute('all', null, {
      [ITEM_FILTERS.SEARCH]: search,
    });
    router.push(url);
  };
  return (
    <Drawer
      scrollable
      position="left"
      open={isOpen}
      onOpenChange={onClose}
      className={fontVariables}
    >
      <div className="relative flex min-h-screen flex-col justify-between bg-black p-4 text-white">
        <div>
          <LogoIcon className="my-4 box-border h-10 w-full stroke-white [&_path]:fill-white [&_path]:stroke-white" />
          <div className="pb-2">
            <CategoriesTreeView />
          </div>
          <div className="space-y-4 uppercase">
            <div
              className="cursor-pointer "
              onClick={() => {
                router.push(`${SHOP_ROUTES.SELLERS}`);
                onClose();
              }}
            >
              <p className="lg:text-lg">
                {tu('all-sellers', 'shop.categories')}
              </p>
            </div>

            <div
              className="cursor-pointer "
              onClick={() => {
                router.push(
                  buildItemsListingRoute('all', null, {
                    [ITEM_FILTERS.ORDER]: ITEM_ORDER.NEW,
                  })
                );
                onClose();
              }}
            >
              <p className="lg:text-lg">{tu('new', 'shop')}</p>
            </div>
            <div
              className="cursor-pointer "
              onClick={() => {
                router.push(
                  buildItemsListingRoute('all', null, {
                    [ITEM_FILTERS.ONLY_SALES]: true,
                  })
                );
                onClose();
              }}
            >
              <p className="lg:text-lg">{tu('sales', 'shop')}</p>
            </div>
            <div
              className="w-fit cursor-pointer"
              onClick={() => {
                router.push(BLOG_ROUTES.HOME);
                onClose();
              }}
            >
              <p
                className={cn(
                  'font-syne text-green-fluo border-green-fluo border-2 px-3 py-0.5 lg:text-lg'
                )}
              >
                stain magazine
              </p>
            </div>
            <div className="h-[1px] bg-white" />
            {isLoggedIn && (
              <>
                <div
                  className="cursor-pointer "
                  onClick={() => {
                    router.push(USER_ROUTES.ACCOUNT);
                    onClose();
                  }}
                >
                  <p className="lg:text-lg">{tu('user.account')}</p>
                </div>
                <div
                  className="cursor-pointer "
                  onClick={() => {
                    router.push(USER_ROUTES.WISHLIST);
                    onClose();
                  }}
                >
                  <p className="lg:text-lg">{tu('user.wishlist.heading')}</p>
                </div>
              </>
            )}
            {isLoggedIn ? (
              <div className="cursor-pointer " onClick={handleLogout}>
                <p className="lg:text-lg">{tu('user.logout')}</p>
              </div>
            ) : (
              <div
                className="cursor-pointer "
                onClick={() => router.push(AUTH_ROUTES.LOGIN)}
              >
                <p className="lg:text-lg">{tu('login', 'auth')}</p>
              </div>
            )}
            <div className="cursor-pointer ">
              <LanguageSelector />
            </div>
          </div>
        </div>
        <div className="flex w-full gap-2">
          <Input
            className="bg-transparent"
            containerClassName="flex-grow"
            placeholder="..."
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleUserSearch();
              }
            }}
          />
          <Button onClick={handleUserSearch}>{tu('common.search')}</Button>
        </div>
      </div>
    </Drawer>
  );
};
